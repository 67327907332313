.container {
  background-color: var(--pri-gray-50);
}

.accordion {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.6s ease-in-out;
}

.open {
  grid-template-rows: 1fr;
}

.inner {
  overflow: hidden;
}

.body {
  padding: 0.25rem 1.5rem 2rem;
}
