.filterBox {
  display: flex;
}

.filePath {
  font-size: 2rem;
  font-weight: 100;
  line-height: 1;
  color: #495057;
}
