/* These container widths were copied from Partnerfleet so we can render a container the same width they do. */
@media (min-width: 600px) {
  .container {
    width: 564px;
  }
}

@media (min-width: 760px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 1010px) {
  .container {
    width: 980px;
  }
}

@media (min-width: 1270px) {
  .container {
    width: 1260px;
  }
}

/* Simulate Partnerfleet's padding so edge of title lines up with Partnerfleet content. */
.header {
  padding-left: calc(17px + 9px);
  padding-right: calc(17px + 9px);
}
