.layout {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    'status userActions'
    'recentWork userActions'
    'usage userActions';
}

/* The widgets in the same column share properties */
.status,
.recentWork,
.usage {
  padding: 1rem;
  padding-left: 5rem;
}

.status {
  grid-area: status;
  background-color: var(--sec-blue-gray-50);
  border-bottom-right-radius: 1rem;
}

.recentWork {
  grid-area: recentWork;
  padding-left: 5rem;
  padding-top: 1.5rem;
}

.usage {
  grid-area: usage;
}

.userActions {
  grid-area: userActions;
  height: 100%;
  padding-top: 1rem;
  background-color: var(--sec-blue-gray-50);
}

.userActionsContent {
  height: 100%;
  padding: 1rem;
  padding-right: 5rem;
  background-color: var(--white);
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

/** md screen breakpoint */
@media (max-width: 767px) {
  .layout {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      'status'
      'recentWork'
      'usage'
      'userActions';
  }

  .status,
  .recentWork,
  .usage,
  .userActions {
    margin-top: 0rem;
    padding: 1rem 1.5rem;
  }

  .status {
    border-radius: 0;
  }

  .recentWork {
    padding-top: 1.5rem;
  }

  .userActions {
    background-color: var(--white);
  }

  .userActionsContent {
    padding-top: 0;
    border-radius: 0;
  }
}

/** xl screen breakpoint */
@media (max-width: 1279px) {
  .status,
  .recentWork,
  .usage {
    padding-left: 2.5rem;
  }

  .userActionsContent {
    padding-right: 2.5rem;
  }
}

/** lg screen breakpoint */
@media (max-width: 1023px) {
  .status,
  .recentWork,
  .usage {
    padding-left: 1.5rem;
  }
  .userActionsContent {
    padding-right: 1.5rem;
  }
}

/*******************************************************************************
Styles when showing CompanySetupGuide.
These styles are defined last so they override the previous styles.
The CompanySetupGuide is only rendered when the page is lg or bigger.
So narrow screen breakpoints do not apply here.
The Homepage changes it's layout to look like the less than md breakpoint to
make room for the CompanySetupGuide.
*******************************************************************************/
.guideLayout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'status'
    'recentWork'
    'usage'
    'userActions';
}

.guideStatus,
.guideRecentWork,
.guideUsage,
.guideUserActions {
  margin-top: 0rem;
  padding: 1rem 1.5rem;
}

.guideStatus {
  margin-right: 1.5rem;
  /* This is a different radius than when the UserActions is in the column beside the status widget. */
  /* I think is looks marginally better. */
  border-radius: 0.5rem;
}

.guideRecentWork {
  padding-top: 1.5rem;
}

.guideUserActions {
  background-color: var(--white);
}

.guideUserActionsContent {
  padding-top: 0;
  border-radius: 0;
}
