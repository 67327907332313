.show2FAKeyButton {
  cursor: pointer;
  color: #069;
  text-decoration: underline;
  border: none;
  background-color: inherit;
}

.show2FAKeyButton:hover {
  text-decoration: none;
}
