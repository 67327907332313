/*
DO NOT SHOW scroll bars when the user IS NOT hovering over this element.
SHOW scroll bars when the user IS hovering over this element.
 */
.schemaList {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.schemaList:hover {
  -ms-overflow-style: unset; /* Internet Explorer 10+ */
  scrollbar-width: unset; /* Firefox */
}
/* Webkit, Safari, and Chrome */
/* I could not figure out what the value was to unset this once I changed it.
So instead, we are only applying it when we are not hovering. */
.schemaList:not(:hover)::-webkit-scrollbar {
  display: none;
}
