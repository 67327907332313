.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#filter {
  max-width: 275px;
}

.addTagBox {
  background-color: var(--pri-gray-25);
  border-color: var(--pri-gray-200);
}

:global(.lineTable) .editTagCell {
  padding: 0px;
  background-color: var(--pri-gray-25);
  border-color: var(--pri-gray-200);
}

:global(.lineTable) .editTagCell td:first-child {
  padding-left: 0px;
}

:global(.lineTable) .editTagCell td:last-child {
  padding-right: 0px;
}
