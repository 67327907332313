.statHeading {
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  color: var(--sec-blue-gray-700);
}

.statTable {
  table-layout: auto;
  border-collapse: collapse;
  font-size: 16px;
  width: 100%;
}

.statTable tbody tr td {
  padding: 8px;
}

.statTable :global(td:nth-child(2)) {
  padding-left: 4px;
}

.statHeading {
  white-space: nowrap;
}

.editColumn {
  padding: 0.25rem 0.5rem !important;
}
