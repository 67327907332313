.syncRotate {
  animation: syncRotation 1.05s infinite linear;
}

@keyframes syncRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
