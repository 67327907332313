.tagPicker {
  position: absolute;
  z-index: 1000;
}

.pickerBox {
  width: 360px; /* This is wide enough to keep NoObjectsAlert.heading from wrapping. */
  border: 1px solid var(--pri-gray-200);
  border-radius: 0.25rem;
  background-color: white;
  /* 
  Layered multiple shadows to get an exponential decay and shadows cast from multiple light sources.
  
  Medium shadow all the way around, large offset soft shadow, small offset hard shadow.
  */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.xBox {
  width: 40px;
  padding: 0px 8px 0px 16px;
  display: flex;
  justify-content: center;
}

.footerButton {
  min-height: 41px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  border-top: 1px solid var(--pri-gray-200);
}

.footerButton:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
