.protipSpinner {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: auto;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.tipCenterDiv {
  width: 60%;
  height: 100%;
  cursor: pointer;
}

.tipImgDiv {
  width: 100%;
  height: 100%;
  display: flex;
}

.tipImgSizeDiv {
  flex-basis: 0;
  flex-grow: 1;
}

.tipImgSizeDiv:not(:first-child) {
  margin-left: 16px;
}

.tipImg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
