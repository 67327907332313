/* The area the mouse can hover over to drag the column. */
.columnHandle {
  position: absolute;
  width: 7px;
  height: 100%;
  right: -4px;
  top: -1px;
  z-index: 1;
  display: flex;
  justify-content: center;
  cursor: col-resize;
  background-color: transparent;
}

/* The part that changes color when the .columnHandle is hovered over. */
.visibleHandle {
  width: 2px;
  height: 100%;
}

.columnHandle:hover .visibleHandle {
  background-color: var(--pri-gray-400);
}

:global(.resizing) .columnHandle .visibleHandle {
  background-color: var(--sec-blue-gray-500);
}
