.popover {
  z-index: 10000;
}

.box {
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  color: white;
  background: var(--pri-gray-700);
}

.arrow,
.arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  z-index: 100001;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  top: 0px;
  left: 0px;
  visibility: visible;
  content: '';
  background: var(--pri-gray-700);
}

.popover[data-popper-placement^='top'] > .arrow {
  bottom: -6px;
}

.popover[data-popper-placement^='bottom'] > .arrow {
  top: -6px;
}

.popover[data-popper-placement^='left'] > .arrow {
  right: -6px;
}

.popover[data-popper-placement^='right'] > .arrow {
  left: -6px;
}

.popover[data-popper-placement^='top'] > .arrow::before {
  transform: rotate(315deg);
}

.popover[data-popper-placement^='bottom'] > .arrow::before {
  transform: rotate(135deg);
}

.popover[data-popper-placement^='left'] > .arrow::before {
  transform: rotate(225deg);
}

.popover[data-popper-placement^='right'] > .arrow::before {
  transform: rotate(45deg);
}
