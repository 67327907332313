.lineTable {
  width: 100%;
  text-align: center;
  text-align: left;
}

.lineTable > thead > tr > th {
  height: 40px;
  padding: 4px 8px 4px 8px;
  border-bottom: 1px solid var(--pri-gray-200);
  color: var(--sec-blue-gray-500);
  font-weight: 500;
}

.lineTable > thead > tr > th:first-child {
  padding-left: 16px;
}

.lineTable > thead > tr > th:last-child {
  padding-right: 16px;
}

.lineTable > tbody > tr > td {
  height: 56px;
  padding: 16px 8px 16px 8px;
  border-bottom: 1px solid var(--pri-gray-200);
  color: var(--pri-gray-500);
}

.lineTable > tbody > tr > td:first-child {
  padding-left: 16px;
}

.lineTable > tbody > tr > td:last-child {
  padding-right: 16px;
}
