.form {
  border-top: 1px solid var(--pri-gray-200);
}

.form.editMode {
  border-top: none;
}

.editMode :global(.lineTable) tbody td {
  border-bottom: none;
  background-color: var(--pri-gray-25);
  border-color: var(--pri-gray-200);
}
