.setupStepsItem {
  display: block;
  padding-left: 38px;
  padding-bottom: 23px;
  margin-top: 23px;
  position: relative;
  counter-increment: muffins;
  border-bottom: 1px solid var(--pri-gray-200);
}

.setupStepsItemTitle {
  font-family: 'Roboto', 'Verdana', 'Oxygen', 'Ubuntu', sans-serif;
  color: black;
  font-weight: 500;
}

.setupStepsItemTitleLink {
  color: var(--sec-blue-gray-500);
  text-decoration: underline;
}

.setupStepsItem:before {
  display: block;
  position: absolute;
  top: -5px;
  left: 0;
  text-align: center;
  font-weight: 700;
  width: 32px;
  height: 32px;
  padding: 4px 2px 2px 2px;
  background: var(--sec-blue-gray-500);
  content: counter(muffins);
  color: white;
  border-radius: 50%;
}

.passwordSubscript {
  @apply text-xs normal-case;
  position: absolute;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  padding-top: 4px;
  color: var(--pri-gray-500);
}
