/*******************************************************************************
 * Code Mirror Themeing
 * https: //codemirror.net/examples/styling/#themes 
 ******************************************************************************/
.cm-editor {
  font-size: 12px;
  color: #212529;
}
/* Disable dashed focus outline that ruins zen look and feel. */
.cm-editor.cm-focused {
  outline: none !important;
}
.cm-line {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
}

/*******************************************************************************
 * Code Mirror styling
 ******************************************************************************/

/* TODO: style active completion */

.cm-tooltip-autocomplete.cm-tooltip.cm-tooltip-below {
  max-width: 400px;
  min-width: 300px;

  white-space: normal;
  /* padding-top: 6px; */
  /* border-top: 1px solid transparent; */
  /* border-top: 1px solid transparent; */
  /* border-bottom: 1px solid transparent; */
  /* padding-bottom: 8px; */
  /* padding-left: 12px; */
  /* padding-right: 12px !important; */
  /* border-left: 4px solid transparent; */
  font-variant-ligatures: none !important;
  margin: 0;
  /* padding: 0 4px; */
  border-radius: 2px;
  white-space: pre;
  color: black !important;
  cursor: pointer;

  overflow-wrap: break-word;
}

.ͼhe .cm-tooltip-autocomplete ul li * {
  overflow-wrap: break-word;
}

.cm-completionLabel {
  font-size: 13.5px;
  line-height: 16px;
  margin-bottom: 2px;
  color: #484d52;
  /* color: green; */
}

.cm-tooltip.cm-tooltip-autocomplete > ul > li {
  /* color: gray !important; */
  background: var(--sec-blue-gray-200);

  white-space: normal;
  cursor: pointer;
  /* padding: 1px 3px; */
  line-height: 1.2;
}

.cm-tooltip.cm-tooltip-autocomplete > ul > li[aria-selected='true'] {
  @apply !drop-shadow-md;
  background: #fff !important;
  border-left: 4px solid #1a6ce7;
  border-radius: 3px;
}

.cm-tooltip.cm-tooltip-autocomplete > ul > li[aria-selected='true'] .cm-completionLabel,
.cm-tooltip.cm-tooltip-autocomplete > ul > li[aria-selected='true'] .cm-completionLabel-modified {
  color: var(--sec-indigo-600) !important;
}

.cm-tootlip-hint-desc {
  font-size: 12px;
  color: black;
}

.cm-completionDetail {
  font-size: 11px;
  color: black;
}

.cm-tooltip-docs-link {
  font-size: 12px;
  text-decoration: underline;
  color: var(--primary-700);
}

.cm-completionLabel-modified + .cm-completionLabel {
  display: none !important;
}
