.list {
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.listItem {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.hasChanges {
  background-color: var(--sec-blue-gray-100);
}

.listItem:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.objectRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.objectBox {
  width: calc(100% - 70px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.description {
  margin-left: 24px;
  font-size: 12px;
  color: #6c757d;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
