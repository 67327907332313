.button {
  position: relative;
  white-space: nowrap;
  border: 2px solid;
  border-radius: 3px;
  outline: 0;
  font-weight: 500;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

/*******************************************************************************
 * Sizes
 ******************************************************************************/
.large {
  height: 46px;
  padding: 9px 14px 9px 14px;
}

.form {
  /* Height matches height of text fields */
  height: 42px;
  padding: 7px 14px 7px 14px;
}

.small {
  height: 28px;
  padding: 2px 14px 2px 14px;
}

.field {
  /* Height matches height of text fields */
  height: 42px;
  padding: 7px 4px 7px 4px;
}

.small.icon {
  padding: 2px 8px 2px 8px;
}

/*******************************************************************************
 * Button Group
 ******************************************************************************/
.buttonGroup {
  display: flex;
}

.buttonGroup .button.canExpand {
  width: 100%;
}

.buttonGroup .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttonGroup .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/*******************************************************************************
 * Light Action Colors
 ******************************************************************************/
.lightAction,
.spinning.lightAction.forceFocus,
.spinning.lightAction:focus,
.spinning.lightAction.forceHover,
.spinning.lightAction:hover,
.spinning.lightAction.forceActive,
.spinning.lightAction:active {
  border-color: var(--sec-purple-100);
  color: var(--purple);
  background-color: var(--sec-purple-100);
}

.lightAction.forceFocus,
.lightAction:focus {
  border-color: var(--sec-purple-300);
}

.lightAction.forceHover,
.lightAction:hover {
  border-color: var(--sec-purple-300);
  background-color: var(--sec-purple-300);
}

.lightAction.forceActive,
.lightAction:active {
  border-color: var(--sec-purple);
  background-color: var(--sec-purple-100);
}

/*******************************************************************************
 * Dark Action Colors
 ******************************************************************************/
.darkAction,
.spinning.darkAction.forceFocus,
.spinning.darkAction:focus,
.spinning.darkAction.forceHover,
.spinning.darkAction:hover,
.spinning.darkAction.forceActive,
.spinning.darkAction:active {
  border-color: var(--purple);
  color: var(--silver);
  background-color: var(--purple);
}

.darkAction.forceFocus,
.darkAction:focus {
  border-color: var(--sec-purple-300);
}

.darkAction.forceHover,
.darkAction:hover {
  border-color: var(--sec-purple-500);
  background-color: var(--sec-purple-500);
}

.darkAction.forceActive,
.darkAction:active {
  border-color: var(--sec-purple-300);
  color: var(--sec-purple-300);
  background-color: var(--purple);
}

/*******************************************************************************
 * Light Dull Action Colors
 ******************************************************************************/
.lightDullAction,
.spinning.lightDullAction.forceFocus,
.spinning.lightDullAction:focus,
.spinning.lightDullAction.forceHover,
.spinning.lightDullAction:hover,
.spinning.lightDullAction.forceActive,
.spinning.lightDullAction:active {
  border-color: var(--sec-blue-gray-100);
  color: var(--sec-blue-gray-500);
  background-color: var(--sec-blue-gray-100);
}

.lightDullAction.forceFocus,
.lightDullAction:focus {
  border-color: var(--sec-blue-gray-300);
}

.lightDullAction.forceHover,
.lightDullAction:hover {
  border-color: var(--sec-blue-gray-200);
  background-color: var(--sec-blue-gray-200);
}

.lightDullAction.forceActive,
.lightDullAction:active {
  border-color: var(--sec-blue-gray-500);
  color: var(--sec-blue-gray-500);
  background-color: var(--sec-blue-gray-100);
}

/*******************************************************************************
 * Dark Dull Action Colors
 ******************************************************************************/
.darkDullAction,
.spinning.darkDullAction.forceFocus,
.spinning.darkDullAction:focus,
.spinning.darkDullAction.forceHover,
.spinning.darkDullAction:hover,
.spinning.darkDullAction.forceActive,
.spinning.darkDullAction:active {
  border-color: var(--sec-blue-gray-500);
  color: white;
  background-color: var(--sec-blue-gray-500);
}

.darkDullAction.forceFocus,
.darkDullAction:focus {
  border-color: var(--sec-blue-gray-200);
}

.darkDullAction.forceHover,
.darkDullAction:hover {
  border-color: var(--sec-blue-gray-400);
  background-color: var(--sec-blue-gray-400);
}

.darkDullAction.forceActive,
.darkDullAction:active {
  border-color: var(--sec-blue-gray-200);
  color: var(--sec-blue-gray-200);
  background-color: var(--sec-blue-gray-500);
}

/*******************************************************************************
 * Save Colors
 ******************************************************************************/
.save,
.spinning.save.forceFocus,
.spinning.save:focus,
.spinning.save.forceHover,
.spinning.save:hover,
.spinning.save.forceActive,
.spinning.save:active {
  border-color: var(--pri-success-700);
  color: white;
  background-color: var(--pri-success-700);
}

.save.forceFocus,
.save:focus {
  border-color: var(--pri-success-200);
  background-color: var(--pri-success-700);
}

.save.forceHover,
.save:hover {
  border-color: var(--pri-success-500);
  background-color: var(--pri-success-500);
}

.save.forceActive,
.save:active {
  border-color: var(--pri-success-200);
  color: var(--pri-success-200);
  background-color: var(--pri-success-700);
}

/*******************************************************************************
 * LightWarning Colors
 ******************************************************************************/
.lightWarning,
.spinning.lightWarning.forceFocus,
.spinning.lightWarning:focus,
.spinning.lightWarning.forceHover,
.spinning.lightWarning:hover,
.spinning.lightWarning.forceActive,
.spinning.lightWarning:active {
  border-color: var(--pri-warning-100);
  color: var(--pri-warning-700);
  background-color: var(--pri-warning-100);
}

.lightWarning.forceFocus,
.lightWarning:focus {
  border-color: var(--pri-warning-300);
}

.lightWarning.forceHover,
.lightWarning:hover {
  border-color: var(--pri-warning-300);
  background-color: var(--pri-warning-300);
}

.lightWarning.forceActive,
.lightWarning:active {
  border-color: var(--pri-warning-700);
  color: var(--pri-warning-700);
  background-color: var(--pri-warning-100);
}

/*******************************************************************************
 * LightDanger Colors
 ******************************************************************************/
.lightDanger,
.spinning.lightDanger.forceFocus,
.spinning.lightDanger:focus,
.spinning.lightDanger.forceHover,
.spinning.lightDanger:hover,
.spinning.lightDanger.forceActive,
.spinning.lightDanger:active {
  border-color: var(--pri-error-100);
  color: var(--pri-error-700);
  background-color: var(--pri-error-100);
}

.lightDanger.forceFocus,
.lightDanger:focus {
  border-color: var(--pri-error-300);
}

.lightDanger.forceHover,
.lightDanger:hover {
  border-color: var(--pri-error-300);
  background-color: var(--pri-error-300);
}

.lightDanger.forceActive,
.lightDanger:active {
  border-color: var(--pri-error-700);
  color: var(--pri-error-700);
  background-color: var(--pri-error-100);
}

/*******************************************************************************
 * Dark Danger Colors
 ******************************************************************************/
.darkDanger,
.spinning.darkDanger.forceFocus,
.spinning.darkDanger:focus,
.spinning.darkDanger.forceHover,
.spinning.darkDanger:hover,
.spinning.darkDanger.forceActive,
.spinning.darkDanger:active {
  border-color: var(--pri-error-700);
  color: var(--silver);
  background-color: var(--pri-error-700);
}

.darkDanger.forceFocus,
.darkDanger:focus {
  border-color: var(--pri-error-300);
}

.darkDanger.forceHover,
.darkDanger:hover {
  border-color: var(--pri-error-500);
  background-color: var(--pri-error-500);
}

.darkDanger.forceActive,
.darkDanger:active {
  border-color: var(--pri-error-300);
  color: var(--pri-error-300);
  background-color: var(--pri-error-700);
}

/*******************************************************************************
 * Light Transparent Colors
 ******************************************************************************/
.lightTransparent,
.spinning.lightTransparent.forceFocus,
.spinning.lightTransparent:focus,
.spinning.lightTransparent.forceHover,
.spinning.lightTransparent:hover,
.spinning.lightTransparent.forceActive,
.spinning.lightTransparent:active {
  border-color: transparent;
  color: var(--purple);
}

.lightTransparent.forceFocus,
.lightTransparent:focus {
  border-color: var(--sec-purple-200);
}

.lightTransparent.forceHover,
.lightTransparent:hover {
  border-color: var(--sec-purple-100);
  background-color: var(--sec-purple-100);
}

.lightTransparent.forceActive,
.lightTransparent:active {
  border-color: var(--purple);
  background-color: transparent;
}

/*******************************************************************************
 * Light Dull Transparent Colors
 ******************************************************************************/
.lightDullTransparent,
.spinning.lightDullTransparent.forceFocus,
.spinning.lightDullTransparent:focus,
.spinning.lightDullTransparent.forceHover,
.spinning.lightDullTransparent:hover,
.spinning.lightDullTransparent.forceActive,
.spinning.lightDullTransparent:active {
  border-color: transparent;
  color: var(--sec-blue-gray-500);
}

.lightDullTransparent.forceFocus,
.lightDullTransparent:focus {
  border-color: var(--sec-blue-gray-200);
}

.lightDullTransparent.forceHover,
.lightDullTransparent:hover {
  border-color: var(--sec-blue-gray-100);
  background-color: var(--sec-blue-gray-100);
}

.lightDullTransparent.forceActive,
.lightDullTransparent:active {
  border-color: var(--sec-blue-gray-500);
  background-color: transparent;
}

/*******************************************************************************
 * Save Transparent Colors
 ******************************************************************************/
.saveTransparent,
.spinning.saveTransparent.forceFocus,
.spinning.saveTransparent:focus,
.spinning.saveTransparent.forceHover,
.spinning.saveTransparent:hover,
.spinning.saveTransparent.forceActive,
.spinning.saveTransparent:active {
  border-color: transparent;
  color: var(--pri-success-700);
}

.saveTransparent.forceFocus,
.saveTransparent:focus {
  border-color: var(--pri-success-300);
}

.saveTransparent.forceHover,
.saveTransparent:hover {
  border-color: var(--pri-success-100);
  background-color: var(--pri-success-100);
}

.saveTransparent.forceActive,
.saveTransparent:active {
  border-color: var(--pri-success-700);
  background-color: transparent;
}

/*******************************************************************************
 * Warning Transparent Colors
 ******************************************************************************/
.warningTransparent,
.spinning.warningTransparent.forceFocus,
.spinning.warningTransparent:focus,
.spinning.warningTransparent.forceHover,
.spinning.warningTransparent:hover,
.spinning.warningTransparent.forceActive,
.spinning.warningTransparent:active {
  border-color: transparent;
  color: var(--pri-warning-700);
}

.warningTransparent.forceFocus,
.warningTransparent:focus {
  border-color: var(--pri-warning-300);
}

.warningTransparent.forceHover,
.warningTransparent:hover {
  border-color: var(--pri-warning-100);
  background-color: var(--pri-warning-100);
}

.warningTransparent.forceActive,
.warningTransparent:active {
  border-color: var(--pri-warning-700);
  background-color: transparent;
}

/*******************************************************************************
 * Danger Transparent Colors
 ******************************************************************************/
.dangerTransparent,
.spinning.dangerTransparent.forceFocus,
.spinning.dangerTransparent:focus,
.spinning.dangerTransparent.forceHover,
.spinning.dangerTransparent:hover,
.spinning.dangerTransparent.forceActive,
.spinning.dangerTransparent:active {
  border-color: transparent;
  color: var(--pri-error-700);
}

.dangerTransparent.forceFocus,
.dangerTransparent:focus {
  border-color: var(--pri-error-300);
}

.dangerTransparent.forceHover,
.dangerTransparent:hover {
  border-color: var(--pri-error-100);
  background-color: var(--pri-error-100);
}

.dangerTransparent.forceActive,
.dangerTransparent:active {
  border-color: var(--pri-error-700);
  background-color: transparent;
}

/*******************************************************************************
 * Light Dull Tab Colors
 ******************************************************************************/
.lightDullTab,
.spinning.lightDullTab.forceFocus,
.spinning.lightDullTab:focus,
.spinning.lightDullTab.forceHover,
.spinning.lightDullTab:hover,
.spinning.lightDullTab.forceActive,
.spinning.lightDullTab:active {
  border-color: transparent;
  color: var(--pri-gray-500);
}

.lightDullTab.forceFocus,
.lightDullTab:focus {
  border-color: transparent;
}

.lightDullTab.forceHover,
.lightDullTab:hover {
  border-color: var(--sec-blue-gray-100);
  background-color: var(--sec-blue-gray-100);
}

.lightDullTab.forceActive,
.lightDullTab:active {
  color: var(--sec-blue-gray-500);
  background-color: transparent;
  border-color: transparent;
}

/*******************************************************************************
 * Disabled
 ******************************************************************************/
.disabled,
.disabled:hover {
  border-color: var(--pri-gray-100);
  color: var(--pri-gray-400);
  background-color: var(--pri-gray-100);
}

.darkAction.disabled,
.darkAction.disabled:hover {
  border-color: var(--pri-gray-300);
  color: var(--pri-gray-100);
  background-color: var(--pri-gray-300);
}

.darkDanger.disabled,
.darkDanger.disabled:hover {
  border-color: var(--pri-error-300);
  color: var(--pri-gray-100);
  background-color: var(--pri-error-300);
}

.darkDullAction.disabled,
.darkDullAction.disabled:hover {
  border-color: var(--sec-blue-gray-200);
  color: white;
  background-color: var(--sec-blue-gray-200);
}

.lightDanger.disabled,
.lightDanger.disabled:hover {
  border-color: var(--pri-error-100);
  color: white;
  background-color: var(--pri-error-100);
}

.save.disabled,
.save.disabled:hover {
  border-color: var(--pri-success-200);
  color: white;
  background-color: var(--pri-success-200);
}

.lightTransparent.disabled,
.lightTransparent.disabled:hover,
.lightDullTransparent.disabled,
.lightDullTransparent.disabled:hover,
.saveTransparent.disabled,
.saveTransparent.disabled:hover,
.warningTransparent.disabled,
.warningTransparent.disabled:hover,
.dangerTransparent.disabled,
.dangerTransparent.disabled:hover,
.lightDullTab.disabled,
.lightDullTab.disabled:hover {
  border-color: transparent;
  color: var(--pri-gray-300);
  background-color: transparent;
}

/*******************************************************************************
 * Adjustments for border being 1px instead of 2px
 ******************************************************************************/
.lightTransparent,
.lightDullTransparent,
.saveTransparent,
.warningTransparent,
.dangerTransparent {
  border: 1px solid transparent;
}

.lightTransparent.large,
.lightDullTransparent.large,
.saveTransparent.large,
.warningTransparent.large,
.dangerTransparent.large {
  padding: 10px 15px 10px 15px;
}

.lightTransparent.form,
.lightDullTransparent.form,
.saveTransparent.form,
.warningTransparent.form,
.dangerTransparent.form {
  padding: 8px 15px 8px 15px;
}

.lightTransparent.small,
.lightDullTransparent.small,
.saveTransparent.small,
.warningTransparent.small,
.dangerTransparent.small {
  padding: 3px 15px 3px 15px;
}

.lightTransparent.small.icon,
.lightDullTransparent.small.icon,
.saveTransparent.small.icon,
.warningTransparent.small.icon,
.dangerTransparent.small.icon {
  padding: 3px 9px 3px 9px;
}
