.angleClosed {
  transition: transform 0.45s; /* Accordions are 0.6. This looks better */
}

.angleOpen {
  transition: transform 0.45s;
}

/*
Accordion Mode: Acts like traditional file explorer.
1. Points in direction of current state.
2. When its closed it points to the right.
*/
.accordionMode.angleClosed {
  transform: rotate(-90deg);
}

.accordionMode.angleOpen {
  transform: rotate(0deg);
}

/*
Button Mode:
1. Points in the direction of change.
2. Points up to indicate close instead of pointing to the right.
*/
.buttonMode.angleClosed {
  transform: rotate(0deg);
}

.buttonMode.angleOpen {
  transform: rotate(-180deg);
}
