.optionsColumn {
  display: flex;
  flex: 1 1 auto;
  width: calc(100% - 216px);
  align-items: center;
  /* 
  overflow-y clips the schedule Listbox options.
  The pipelines project is about to change this UI.
  So, this is a short lived hack.
  */
  overflow-x: clip;
  overflow-y: visible;
}

.editColumn {
  margin-left: 8px;
  display: flex;
  justify-content: flex-end;
  flex: 0 0 content;
}
