@keyframes expand {
  0% {
    max-height: 0px;
  }

  100% {
    max-height: var(--advanced-search-height);
  }
}

@keyframes collapse {
  0% {
    max-height: var(--advanced-search-height);
  }

  100% {
    max-height: 0px;
  }
}

.accordion {
  overflow: hidden;
  max-height: none;
  /* 27 rows of height 23px plus the height of the checkbox row and its margin */
  --advanced-search-height: calc((27 * 23px) + (24px + 8px));
}

.accordionClosed {
  max-height: 0px;
}

.accordionOpen {
  max-height: var(--advanced-search-height);
}

.animateClosed {
  animation: collapse 0.6s ease-in-out 0s 1 normal forwards;
}

.animateOpen {
  animation: expand 0.6s ease-in-out 0s 1 normal none;
}

.advancedSearch {
  margin-top: 14px;
  margin-left: 14px;
}

.table td:nth-child(2) {
  padding-left: 14px;
}

.pattern {
  color: var(--sec-rose-600);
}
