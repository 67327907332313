/*******************************************************************************
 * Define Color Palette
 ******************************************************************************/
:root {
  --white: #ffffff;
  --purple: #4f35b3;
  --teal: #0b9f84;
  --teal-dark: #004046;
  --teal-light: #38d3b6;
  --gold: #ffb01e;
  --silver: #f0f1f7;
  --orange: #ff4f01;
  --primary-900: #271a58;
  --primary-800: #30206c;
  --primary-700: #3f2c87;
  --primary-600: #4f35b3;
  --primary-50: #d1c9ef;
  --primary-25: #dfd9f4;

  --pri-gray-900: #101928;
  --pri-gray-800: #1d2939;
  --pri-gray-700: #344054;
  --pri-gray-600: #475467;
  --pri-gray-500: #667085;
  --pri-gray-400: #98a2b3;
  --pri-gray-300: #d0d5dd;
  --pri-gray-200: #e4e7ec;
  --pri-gray-100: #f2f4f7;
  --pri-gray-50: #f9fafb;
  --pri-gray-25: #fcfcfd;
  --pri-error-900: #7a271a;
  --pri-error-800: #912018;
  --pri-error-700: #b42318;
  --pri-error-600: #d92d20;
  --pri-error-500: #f04438;
  --pri-error-400: #f97066;
  --pri-error-300: #fda29b;
  --pri-error-200: #fecdca;
  --pri-error-100: #fee4e2;

  --pri-warning-700: #b54708;
  --pri-warning-600: #dc6803;
  --pri-warning-500: #f79009;
  --pri-warning-400: #fdb022;
  --pri-warning-300: #fec84b;
  --pri-warning-200: #fedf89;
  --pri-warning-100: #fef0c7;
  --pri-warning-50: #fffaeb;

  --pri-success-700: #027a48;
  --pri-success-600: #039855;
  --pri-success-500: #12b76a;
  --pri-success-400: #32d583;
  --pri-success-300: #6ce9a6;
  --pri-success-200: #a6f4c5;
  --pri-success-100: #d1fadf;
  --pri-success-50: #ecfdf3;

  --sec-blue-gray-800: #293056;
  --sec-blue-gray-700: #363f72;
  --sec-blue-gray-600: #3e4784;
  --sec-blue-gray-500: #4e5ba6;
  --sec-blue-gray-400: #717bbc;
  --sec-blue-gray-300: #9ea5d1;
  --sec-blue-gray-200: #c8cce5;
  --sec-blue-gray-100: #eaecf5;
  --sec-blue-gray-50: #f8f9fc;
  --sec-blue-gray-25: #fcfcfd;

  --sec-blue-light-700: #026aa2;
  --sec-blue-light-600: #0086c9;
  --sec-blue-light-500: #0ba5ec;
  --sec-blue-light-400: #36bffa;
  --sec-blue-light-300: #7cd4fd;
  --sec-blue-light-200: #b9e6fe;
  --sec-blue-light-100: #e0f2fe;
  --sec-blue-light-50: #f0f9ff;

  --sec-purple-700: #5925dc;
  --sec-purple-500: #7a5af8;
  --sec-purple-400: #9b8afb;
  --sec-purple-300: #bdb4fe;
  --sec-purple-200: #d9d6fe;
  --sec-purple-100: #ebe9fe;
  --sec-purple-50: #f4f3ff;
  --sec-purple-25: #fafaff;
  --sec-orange-100: #ffead5;
  --sec-orange-50: #fff6ed;
  --sec-orange-700: #c4320a;
  --sec-orange-600: #ec4a0a;
  --sec-orange-500: #fb6514;
  --sec-orange-400: #fd853a;
  --sec-orange-300: #feb273;
  --sec-orange-200: #fddcab;

  --sec-rose-700: #c01048;
  --sec-rose-600: #e31b54;
  --sec-rose-500: #f63d68;
  --sec-rose-400: #fd6f8e;
  --sec-rose-300: #fea3b4;
  --sec-rose-200: #fecdd6;
  --sec-rose-100: #ffe4e8;
  --sec-rose-50: #fff1f3;

  --sec-indigo-700: #3538cd;
  --sec-indigo-600: #444ce7;
  --sec-indigo-500: #6172f3;
  --sec-indigo-400: #8098f9;
  --sec-indigo-300: #a4bcfd;
  --sec-indigo-200: #c7d7fe;
  --sec-indigo-100: #e0eaff;
  --sec-indigo-50: #eef4ff;

  --sec-blue-700: #175cd3;
  --sec-blue-600: #1570ef;
  --sec-blue-500: #2e90fa;
  --sec-blue-400: #53b1fd;
  --sec-blue-300: #84caff;
  --sec-blue-200: #b2ddff;
  --sec-blue-100: #d1e9ff;
  --sec-blue-50: #eff8ff;
  --search-yellow: #fff0a6;
}

/*******************************************************************************
 * Init Tailwind
 ******************************************************************************/
@tailwind base;
@tailwind components;
@tailwind utilities;

/*******************************************************************************
 * Custom Tailwind Utilities
 ******************************************************************************/
@layer utilities {
  .row-split {
    @apply grid gap-4 grid-cols-2 grid-rows-1;
  }
}

/*******************************************************************************
 * Global Fonts
 ******************************************************************************/
@font-face {
  font-family: 'Roboto';
  src: url('/public/fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('/public/fonts/roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('/public/fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
}

body {
  font-family: 'Roboto', 'Verdana', 'Oxygen', 'Ubuntu', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: var(--pri-gray-700);
  /*
  These are non-standard properties not on track to be standardized,
  but they do make the fonts look nicer(ie less bold as they do in Zeplin).
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: var(--sec-purple-700);
}

/*******************************************************************************
 * Design system font sizes not in tailwind.config.js
 ******************************************************************************/

.display-xl {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -1.2px;
}
.display-lg {
  font-size: 48px;
  line-height: 1.25;
  letter-spacing: -0.96px;
}
.display-base {
  font-size: 36px;
  line-height: 1.22;
  letter-spacing: -0.72px;
}
.display-sm {
  font-size: 30px;
  line-height: 1.27;
  letter-spacing: normal;
}
.display-xs {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
}

.text-input-label {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--purple);
}

/*******************************************************************************
 * Homegrown Flex Utilities
 ******************************************************************************/
.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.f-col {
  display: flex;
  flex-direction: column;
}

.f-row-y-center {
  display: flex;
  align-items: center;
}

.f-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*******************************************************************************
 * Global Animations
 ******************************************************************************/
.newListItem {
  animation-duration: 2.3s;
  animation-name: new-item-flashin;
}

@keyframes new-item-flashin {
  from {
    background-color: var(--pri-success-100);
  }

  to {
    background-color: #fff;
  }
}

/*******************************************************************************
 * Global Input Styles
 ******************************************************************************/
/* Hide arrows in number inputs on all browsers */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
