.input:checked,
.input:checked:focus,
.input:checked:hover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-color: white;
}

.purple:checked,
.purple:checked:focus,
.purple:checked:hover {
  /* 
  You can't put non-sanctioned elements inside an input element, so hard code Bootstrap Icon SVG in background style.
  Note the %23 escaping the # sign in the fill attribute.
  */
  background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022h-.001z" fill="%234f35b3"/></svg>');
}

.purpleDisabled:checked,
.purpleDisabled:checked:focus,
.purpleDisabled:checked:hover {
  /* 
  You can't put non-sanctioned elements inside an input element, so hard code Bootstrap Icon SVG in background style.
  Note the %23 escaping the # sign in the fill attribute.
  */
  background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022h-.001z" fill="%23d0d5dd"/></svg>');
}

.blueGray:checked,
.blueGray:checked:focus,
.blueGray:checked:hover {
  /* 
  You can't put non-sanctioned elements inside an input element, so hard code Bootstrap Icon SVG in background style.
  Note the %23 escaping the # sign in the fill attribute.
  */
  background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022h-.001z" fill="%234e5ba6"/></svg>');
}

.blueGrayDisabled:checked,
.blueGrayDisabled:checked:focus,
.blueGrayDisabled:checked:hover {
  /* 
  You can't put non-sanctioned elements inside an input element, so hard code Bootstrap Icon SVG in background style.
  Note the %23 escaping the # sign in the fill attribute.
  */
  background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022h-.001z" fill="%23c8cce5"/></svg>');
}
