.flowchartVertex {
}

.hoverButtonDiv {
  display: none;
}

.flowchartVertex:hover .hoverButtonDiv {
  display: flex;
}

.errorIcon:hover {
  animation: pop 0.25s linear;
}

@keyframes pop {
  50% {
    transform: scale(1.5);
  }
}
