.colVertCenter {
  height: 48px;
  display: flex;
  align-items: center;
}

.syncSpinner {
  width: 26px;
  height: 26px;
}

.lastSyncCol {
  line-height: 31px;
}

.scSchemaCheckRow {
  width: 497px;
  padding: 1px;
}

.scSchemaExpandBox {
  width: 497px;
  padding-left: 21px;
}

.SchemaConfigColumnTable {
  margin-bottom: 0px;
}

.SchemaConfigColumnTable thead th {
  background-color: #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.SchemaConfigColumnTable th {
  padding: 0px;
}

.SchemaConfigColumnTable th,
.SchemaConfigColumnTable td {
  padding: 0px;
  padding-bottom: 1px;
}
