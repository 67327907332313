.pickerBox {
  position: absolute;
  top: calc(36px + 0.5rem);
  right: 0;
  z-index: 11;
  display: flex;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #fff;
}

.backDrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}
