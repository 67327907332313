.nameCell {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:global(.table td).descriptionCell {
  max-width: 0;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tagPill {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 4px;
  font-size: 0.75rem;
}

.clickableIconHeight {
  height: 16px;
}

.queryLink {
  display: inline-block;
}

.queryLink:hover {
  font-weight: 500;
  color: var(--sec-blue-gray-500);
  text-decoration: underline;
}

:global(.svg-inline--fa.fa-w-14).queryIcon {
  height: 16px;
  width: 16px;
  transform: scale(
    0.87,
    1
  ); /* Adjacent icons are different shapes. Make them look nicer next to each other. */
  color: #6c757d;
}

td.notApplicableCell {
  color: var(--pri-gray-400) !important;
}

.favoriteIcon {
  width: 20px;
  height: 20px;
  padding: 1px !important;
}

.tagPickerIcon {
  width: 20px;
  height: 20px;
  padding: 1px !important;
  /* The tag icon is not centered well by bootstrap. Compensate. */
  position: relative;
  top: 1px;
}
