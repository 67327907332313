.blueGrayHeaderTable {
  width: 100%;
  text-align: left;
}

.blueGrayHeaderTable > thead > tr > th {
  height: 36px;
  padding: 8px;
  color: var(--sec-blue-gray-500);
  background-color: var(--sec-blue-gray-100);
  font-size: 14px;
  line-height: 1.43;
  font-weight: 500;
  white-space: nowrap;
}

.blueGrayHeaderTable > tbody > tr > td {
  height: 41px;
  padding: 10px 8px 10px 8px;
  border-bottom: 1px solid var(--pri-gray-200);
  font-size: 14px;
  line-height: 1.43;
  color: var(--pri-gray-700);
}
