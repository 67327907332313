.spinnerRotate {
  animation: spinnerRotation 1s infinite linear;
}

@keyframes spinnerRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mozartSpinner {
  width: 16px;
  height: 16px;
}

.mozartSpinnerMedium {
  width: 24px;
  height: 24px;
}
