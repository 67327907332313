.container {
  width: 30px;
  height: 24px;
}

.switch {
  position: relative;
  width: 30px;
  height: 18px;
  border: 2px solid var(--sec-blue-gray-500);
  border-radius: 9px;
  background-color: white;
  transition: background-color 0s ease-in-out 0.15s;
}

.switch:checked,
.switch:checked:focus {
  background-color: var(--sec-blue-gray-500);
  background-image: none;
  transition: background-color 0s ease-in-out 0s;
}

.switch:hover {
  cursor: pointer;
}

.switch:checked:hover {
  background-color: var(--sec-blue-gray-500);
}

.switch::after {
  position: absolute;
  top: -2px;
  left: -2px;
  content: '';
  width: 18px;
  height: 18px;
  border: 2px solid var(--sec-blue-gray-500);
  border-radius: 9px;
  background-color: white;
  transition: left 0.15s ease-in-out;
}

.switch:checked::after {
  left: 10px;
  transition: left 0.15s ease-in-out;
}

.switch:disabled {
  border-color: var(--pri-gray-400);
  cursor: default;
}

.switch:checked:disabled {
  border-color: var(--pri-gray-400);
  background-color: var(--pri-gray-400);
}

.switch:disabled:after {
  border-color: var(--pri-gray-400);
}
