/*******************************************************************************
 * AceEditor
 ******************************************************************************/
.ace-code {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
  color: #212529;
}

.ace-keyword {
  color: #8959a8;
}

.ace-error-marker {
  position: absolute;
  background-color: #f7a5ac80;
}

/*******************************************************************************
 * TableList
 ******************************************************************************/
.tt-table-list {
  height: 100%;
  overflow: hidden;
  background-color: var(--white);
  padding: 8px;
  display: flex;
  flex-direction: column;
}

#tt-table-list-filter {
  margin-bottom: 3px;
}

.tt-table-name-list {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.tt-table-list-scrolling-area {
  width: 100%;
  height: 100%;
}

.tt-table-list-name-list {
  width: 100%;
  font-size: 14px;
  line-height: 1.43;
  color: var(--pri-gray-700);
}

.tt-table-list-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 8px;
  cursor: pointer;
}

.tt-schema-row:hover,
.tt-table-list-row:hover:not(.tt-table-list-disabled-row) {
  background-color: var(--pri-gray-100);
}

.tt-table-list-selected-row {
  background-color: var(--sec-blue-gray-100);
}

.tt-table-list-selected-row:hover {
  background-color: var(--sec-blue-gray-100);
}

.tt-table-list-indented-row {
  padding-left: 36px;
}

/* Slight tweak of tt-table-list-row */
.tt-schema-row {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 4px 8px;
  cursor: pointer;
}

.tt-table-list-drag-space {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--pri-gray-300);
}

.tt-table-list-disabled-row {
  color: var(--pri-gray-400);
  cursor: default;
}

/*******************************************************************************
 * Button Group Used by Both Table List and Table Details
 ******************************************************************************/
.tt-table-buttons {
  position: absolute;
  z-index: 1;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: inherit;
  padding: 0;
}

/*******************************************************************************
 * TableDetails
 ******************************************************************************/
.tt-table-details-scrolling-area {
  width: 100%;
}

.tt-table-details-header {
  color: var(--pri-gray-900);
  font-weight: 500;
}

.tt-table-details-columns {
  width: 100%;
}

.tt-table-details-columns td {
  height: 29px;
  padding: 0;
}

.tt-table-details-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  height: 29px;
}

.tt-table-details-row:hover {
  background-color: var(--pri-gray-100);
}

.tt-table-details-row-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 29px;
  padding: 4px 8px;
  border-bottom: 1px solid var(--pri-gray-200);
  border-top: none;
}

.tt-table-details-row-name {
  color: var(--pri-gray-700);
}

.tt-table-details-row-type {
  color: var(--pri-gray-400);
}

.tt-table-details-sample-row {
  padding: 0.3rem;
  max-height: calc(calc(calc(0.9rem * 1.2) + 0.6rem) + 1px);
  overflow: hidden;
  vertical-align: middle;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.2;
  white-space: nowrap;
}

.tt-table-details-sample-row:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
}

/*******************************************************************************
 * ChartModal
 ******************************************************************************/
.tt-chart-modal-dialog {
  max-width: calc(100vw - 4rem);
}

@media (min-width: 992px) {
  .tt-chart-modal-dialog {
    max-width: 80%;
  }
}

.tt-chart-modal-type {
  color: #656565;
  border-color: #000;
}

.tt-chart-modal-type:hover {
  color: #fff;
}

.active.tt-chart-modal-type {
  background-color: #cacaca;
  color: #000;
}

.tt-chart-modal-cols {
  display: flex;
}

.tt-chart-modal-col-list {
  width: calc(250px + 0.4em + 2px);
  min-height: 40.75px;
  background-color: #eaeaea;
  border: #656565 solid 1px;
  border-radius: 0.25em;
  overflow: hidden;
}

.tt-chart-modal-col-list-text {
  padding: 0.2em;
  margin: 0.2em;
  border: transparent solid 1px;
  color: #656565;
  white-space: nowrap;
}

.tt-chart-modal-col {
  width: 250px;
  margin: 0.2em;
  padding: 0.2em;
  overflow: hidden;
  background: #cacacaff;
  border: black solid 1px;
  border-radius: 0.25em;
  cursor: pointer;
}

.tt-chart-modal-col-dragging {
  background-color: #9cdefd;
}

/*******************************************************************************
 * ShowTransform
 ******************************************************************************/
.transform-tabs .tab-content {
  height: 100%;
  overflow: hidden;
}
