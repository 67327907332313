.menu {
  position: relative;
  display: inline-block;
}

.menuItems {
  transform-origin: top right;
  position: absolute;
  right: 0px;
  margin-top: 8px;
  border-radius: 6px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  background-color: white;
  z-index: 2000;
  animation: scrollDown 0.3s;
  overflow: hidden;
}

.menuItems:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.menuItem {
  white-space: nowrap;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
}

.menuItem:hover {
  background-color: var(--sec-purple-100);
}

.menuItemDisabled {
  color: var(--pri-gray-300);
  cursor: default;
}

.menuItemDisabled:hover {
  background-color: white;
}

@keyframes scrollDown {
  from {
    transform: scale(1, 0.1);
  }
}
