.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;
}

.controls > * {
  margin-bottom: 0.5rem;
}
