.dotPulse {
  animation: pulse 0.75s 10;
  opacity: 100%;
}

@keyframes pulse {
  to {
    transform: scale(2);
    opacity: 0%;
  }
}
