.colorlessHeaderBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.colorlessHeaderBox .totalRows {
  display: flex;
}

.colorlessHeaderBox .totalRow {
  display: flex;
  flex-direction: column;
  margin-right: 48px;
}

.colorlessHeaderBox .totalRowItem {
  display: flex;
}

.colorlessHeaderBox .total {
  display: flex;
  justify-content: flex-end;
  font-size: 30px;
  line-height: 1.27;
  margin-top: 4px;
}

.colorlessHeaderBox .helpLink {
  display: flex;
  align-self: flex-end;
  font-weight: normal;
}
