.keyRow {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 20px;
  margin-bottom: 8px;
  font-size: 14px;
  color: #495057;
}

.pathLabel {
  font-weight: 500;
}

.pathValue {
  font-weight: 500;
  color: #495057;
}

.pathEscape {
  font-size: 14px;
  color: #495057;
}
