.columnizer {
  width: 100%;
  padding: 0px 8px 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.columnTag {
  display: inline-flex;
  align-items: center;
  height: 28px;
  padding: 0px 0px 0px 12px;
  margin-top: 4px;
  margin-right: 8px;
  border-radius: 14px;
  background-color: var(--sec-blue-gray-100);
  color: var(--sec-blue-gray-500);
  cursor: move;
}

.columnTag.dragging {
  opacity: 0.25;
}

.columnTag.dragEntered {
  background-color: var(--pri-success-200);
  border-style: dotted;
  box-shadow: 0 0 0 0.3rem var(--pri-success-400);
}

.columnTag .name {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  pointer-events: none;
}

.columnTag .removeButton {
  padding: 2px 14px 2px 4px;
}

.columnTag .removeButton:hover {
  cursor: pointer;
}

.controls {
  flex-shrink: 0;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
