.inlineIconBox {
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
}

.icon:hover {
  animation: pop 0.25s linear;
}

@keyframes pop {
  50% {
    transform: scale(1.5);
  }
}
