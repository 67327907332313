.tagPill {
  display: inline-block;
  max-width: 100%;
  padding: 0em 0.75em 0em 0.75em;
  border-radius: 0.95rem;
  font-size: 1em;
  line-height: 1.5;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.disableLink {
  cursor: default;
}
