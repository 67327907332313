.svg {
  width: 86px;
  height: 60px;
  overflow: visible;
}

.pipes {
  transform-origin: center center;
  animation: rotate 1.2s infinite ease-in-out;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
  from {
    transform: rotate(0deg);
  }
}
