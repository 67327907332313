.filterCount {
  margin-left: 8px;
  font-size: 20px;
  line-height: 1.5;
  color: var(--pri-gray-500);
}

.filterInput {
  width: 42%;
  max-width: 569px;
}
