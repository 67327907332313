.table {
  table-layout: fixed;
}

.table thead th {
  position: relative;
  vertical-align: bottom;
}

.table tbody tr:hover {
  background-color: var(--pri-gray-100);
}

.descriptionPopover {
  white-space: pre-wrap;
}
