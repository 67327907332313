.listItemContainer {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--sec-purple-50);
}

.listItemSelected {
  background-color: var(--sec-purple-200);
}

/* 
  NOTE: For some reason this class conflicts with the already applied `listItemContainer`, so we need to 
  make sure this definition is ordered after `listItemContainer` in this file. Otherwise, the `display`
  style is overwritten by the `display` prop in `listItemContainer`.
*/
.defaultGhostHidden {
  display: none;
}

.dragHandle {
  /* Fallback for IE */
  cursor: move;

  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.dragHandle:active,
.draggingHandle {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
