/* 
I could not get tailwind rule (bg-[url(FILE_PATH)]) to work.
So using old school CSS class.
*/

.input:checked,
.input:checked:focus,
.input:checked:hover {
  background-color: white;
  background-image: url('/public/images/icons/check.svg');
}

.input:disabled {
  cursor: default;
  border-color: var(--pri-gray-400);
}

.input:checked:disabled {
  background-image: url('/public/images/icons/disabled_check.svg');
}
