/*******************************************************************************
 * Helpers
 ******************************************************************************/
.margined {
  margin-bottom: 10px;
  margin-top: 10px;
}

.margined-top {
  margin-top: 10px;
}

.margined-bottom {
  margin-bottom: 10px;
}

.btn-margined {
  margin: 10px;
}

.full-height {
  height: 100%;
  overflow: hidden;
}

.as-link {
  padding: 0;
  border: none;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.as-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

/*******************************************************************************
 * Layout Styles
 ******************************************************************************/
.page-title {
  font-size: 2rem;
}

/*******************************************************************************
 * Specific Components
 ******************************************************************************/
#page-content {
  min-height: calc(100vh - calc(3 * 34px)); /* Screen Height - mobile footer */
}

@media (min-width: 1024px) {
  #page-content {
    min-height: calc(100vh - 34px); /* Screen Height - desktop footer */
  }
}

#hotkey-modal-body .tab-content {
  height: calc(100% - 42px);
}

.table.version-history-table thead th {
  border-top: 0;
}

/*******************************************************************************
 * Small Size Switch
 ******************************************************************************/
.smSize.custom-switch {
  padding-left: 2.75rem;
}

/*******************************************************************************
 * Medium Size Switch
 ******************************************************************************/
.mdSize.custom-control {
  min-height: calc(1.5rem * 1.4);
  padding-left: calc(1.5rem * 1.4);
}

.mdSize.custom-switch {
  padding-left: calc(2.25rem * 1.4);
}

.mdSize .custom-control-input {
  width: calc(1rem * 1.4);
  height: calc(1.25rem * 1.4);
}

.mdSize.custom-switch .custom-control-label::before {
  top: calc(0.25rem * 1.4);
  left: calc(-2.25rem * 1.4);
  width: calc(1.75rem * 1.4);
  height: calc(1rem * 1.4);
  border-radius: calc(0.5rem * 1.4);
}

.mdSize.custom-switch .custom-control-label::after {
  top: calc(calc(0.25rem * 1.4) + 2px);
  left: calc(calc(-2.25rem * 1.4) + 2px);
  width: calc(calc(1rem * 1.4) - 4px);
  height: calc(calc(1rem * 1.4) - 4px);
  border-radius: calc(0.5rem * 1.4);
}

.mdSize.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(0.75rem * 1.4));
}

/*******************************************************************************
 * Images
 ******************************************************************************/
.image-fit {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/*******************************************************************************
 * Modals
 ******************************************************************************/
.modal-full-width {
  width: calc(100vw - 1rem);
  max-width: 2400px;
}

@media (min-width: 932px) {
  .modal-medium-xl {
    max-width: 900px;
  }
}

@media (min-width: 576px) {
  .modal-full-width {
    width: calc(100vw - 3.5rem);
  }
}

.modal-full-height {
  height: calc(100vh - 1rem);
}

@media (min-height: 576px) {
  .modal-full-height {
    height: calc(100vh - 3.5rem);
  }
}

/*******************************************************************************
 * Tables
 ******************************************************************************/
.table-fix-head thead th {
  position: sticky;
  top: 0;
  background: #eee;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  z-index: 5; /* Render header above scrollable content. */
}

/*
pseudo element fixes bug in chrome rendering 
https://stackoverflow.com/questions/57166162/table-headers-positionsticky-and-border-issue/57170489
*/
.table-fix-head thead th:after,
.table-fix-head thead th:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}

.table-fix-head thead th:before {
  top: -1px;
  border-top: 1px solid #dee2e6;
}

.table-fix-head thead th:after {
  bottom: -1px;
  border-bottom: 1px solid #dee2e6;
}

.table-one-line thead th {
  border: none;
}

/*******************************************************************************
 * Hide the Osano widget.
 * The user opens the Osano drawer with the link in the footer.
 ******************************************************************************/
.osano-cm-widget {
  display: none;
}
