.popover {
  z-index: 10000;
}

.box {
  padding: 16px;
  max-width: 300px;
  border: 1px solid var(--pri-gray-300);
  border-radius: 4px;
  background-color: white;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 100001;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  top: 0px;
  left: 0px;
  visibility: visible;
  content: '';
  background: white;
  border-left: 1px solid var(--pri-gray-300);
  border-bottom: 1px solid var(--pri-gray-300);
}

.popover[data-popper-placement^='top'] > .arrow {
  bottom: -8px;
}

.popover[data-popper-placement^='bottom'] > .arrow {
  top: -8px;
}

.popover[data-popper-placement^='left'] > .arrow {
  right: -8px;
}

.popover[data-popper-placement^='right'] > .arrow {
  left: -8px;
}

.popover[data-popper-placement^='top'] > .arrow::before {
  transform: rotate(315deg);
}

.popover[data-popper-placement^='bottom'] > .arrow::before {
  transform: rotate(135deg);
}

.popover[data-popper-placement^='left'] > .arrow::before {
  transform: rotate(225deg);
}

.popover[data-popper-placement^='right'] > .arrow::before {
  transform: rotate(45deg);
}
