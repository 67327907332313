.container {
  padding: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  background-color: white;
}

.item {
  margin: 0 0.25rem;
}

/* The following two styles allows the row of items (i.e. vertices) to be centered if it doesn't overflow */
.item:first-child {
  margin-left: auto;
}

.item:last-child {
  margin-right: auto;
}
