.buttonKey {
  height: 20px;
  padding: 0px 5px 0px;
  border: 1px solid #999999;
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  border-radius: 3px;
  color: rgb(0, 0, 0);
  white-space: pre;
  background-color: #f1f1f1;
  box-shadow: inset 0 -1px 0 #dee2e6;
}

.small {
  height: 16px;
  padding: 0px 3px 0px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 2px;
}

.dull {
  color: var(--pri-gray-500);
  border: 1px solid var(--pri-gray-300);
  background-color: var(--pri-gray-25);
  box-shadow: inset 0 -1px 0 #efefef; /* I hand tuned this color. */
}
