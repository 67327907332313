.titleRow {
  display: flex;
  justify-content: left;
}

.expandingColumn {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
}
