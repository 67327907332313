.form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 0.5rem;
}

.buttonRow {
  grid-column: 1 / span 2;
  display: flex;
  align-items: center;
}
