.outerDiv {
  position: relative;
  width: 100%;
  min-height: calc(100vh - calc(3 * 34px));
  overflow: hidden;
}

.backgroundGradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left,
    var(--silver),
    var(--white) calc(50% - 0px),
    var(--white) calc(50% + 0px),
    var(--silver) 100%
  );
  z-index: -50;
}

@media (min-width: 1024px) {
  .outerDiv {
    height: calc(100vh - 34px);
  }

  .backgroundGradient {
    background: linear-gradient(
      to left,
      var(--silver),
      var(--white) calc(50% - 230px),
      var(--white) calc(50% + 230px),
      var(--silver) 100%
    );
  }
}
