.runResults {
  width: 100%;
  height: 100%; /* Use entire height so we don't worry about scroll bar width */
  position: relative;
  display: flex; /* Make sure scroll-area uses my height */
  overflow: hidden;
  border-top: 1px solid var(--pri-gray-200);
}

.hideBorder {
  border: none;
}

.runResultsScrollArea {
  width: 100%;
  height: 100%;
  overflow: auto;
}

/*
A ResultsTable is actually four tables(.resultsTableCorner, .resultsTableHeader, .resultsTableResults, .resultsTableIndexes)
that move independently as the user scrolls.
This class is the properties that are common to all four.
*/
.resultsTable {
  --contentSize: 18px;
  margin-bottom: 0px;
  position: absolute;
  table-layout: fixed;
  border: none;
}

.resultsTable th,
.resultsTable td,
.table-sm .resultsTable th,
.table-sm .resultsTable td {
  padding: 5px 8px 5px 8px; /* The mockup has a vertical padding of 4px, which feels ever so tight in practice. Making it 5px. */
  max-width: 350px;

  /* .resultsTable can be nested inside of .blueGrayHeaderTable. Override any styles it sets. */
  height: unset;
  border-bottom: unset;
  color: var(--pri-gray-700);
  background-color: var(--white);
  font-size: 12px;
  line-height: var(--contentSize);
  font-weight: normal;
  white-space: nowrap;
  letter-spacing: 0.3px;
  text-align: left;
  vertical-align: middle;
}

.resultsTable thead th {
  /* Browsers only compute height with borders correctly when the height is on the cell. */
  height: 47px;
  border-bottom: 1px solid var(--pri-gray-200);
}

/* 
You can't seem to apply overflow rules to elements with `display:table`.
So, apply it to div inside th.
*/
.columnName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.resultsTableCorner {
  position: absolute;
  z-index: 4;
}

.resultsTable.resultsTableCorner thead th {
  width: 50px;
}

.resultsTableHeader {
  position: absolute;
  z-index: 3;
}

.resultsTableHeader th {
  font-weight: 500;
}

.resultsTable.resultsTableHeader .dataType {
  font-weight: normal;
  color: var(--pri-gray-500);
}

.resultsTable.resultsTableHeader .resultsTableSelectedColumn {
  background-color: #c8cce5;
}

.resultsTableIndexes {
  position: absolute;
  z-index: 2;
}

.resultsTable.resultsTableIndexes tbody td {
  text-align: right;
  background-color: var(--pri-gray-100);
}

.resultsTableResults {
  z-index: 1;
}

.resultsTableResults tbody tr:nth-of-type(even) td {
  background-color: var(--pri-gray-50);
}

.resultsTableResults tbody tr:hover td {
  background-color: var(--pri-gray-200);
}

.resultsTableResults.size1 td,
.resultsTableIndexes.size1 td {
  height: calc(calc(var(--contentSize) * 1) + 11px);
}

.resultsTableResults.size3 td,
.resultsTableIndexes.size3 td {
  height: calc(calc(var(--contentSize) * 3) + 11px);
}

.resultsTableResults.size5 td,
.resultsTableIndexes.size5 td {
  height: calc(calc(var(--contentSize) * 5) + 11px);
}

.resultsTableResults.size10 td,
.resultsTableIndexes.size10 td {
  height: calc(calc(var(--contentSize) * 10) + 11px);
}

.resultsTableResults td div {
  overflow: hidden;
  line-height: var(--contentSize);
}

.resultsTableResults.size1 td div {
  height: calc(var(--contentSize) * 1);
}

.resultsTableResults.size3 td div {
  height: calc(var(--contentSize) * 3);
  white-space: pre-wrap;
  text-align: left;
}

.resultsTableResults.size5 td div {
  height: calc(var(--contentSize) * 5);
  white-space: pre-wrap;
  text-align: left;
}

.resultsTableResults.size10 td div {
  height: calc(var(--contentSize) * 10);
  white-space: pre-wrap;
  text-align: left;
}

.resultsTableNoRows {
  min-height: 56px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
}
