@keyframes expand {
  0% {
    max-height: 0px;
  }
  100% {
    max-height: 600px;
  }
}

@keyframes collapse {
  0% {
    max-height: 600px;
  }
  100% {
    max-height: 0px;
  }
}

.accordion {
  overflow: hidden;
  max-height: none;
}

.accordionClosed {
  animation: collapse 0.6s ease-in-out 0s 1 normal forwards;
}

.accordionOpen {
  animation: expand 0.6s ease-in-out 0s 1 normal none;
}
