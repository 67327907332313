.modal {
  width: 784px;
  position: absolute;
  top: 80px;
  left: 50%;
  right: auto;
  bottom: auto;
  overflow: hidden;
  outline: none;
  padding: 16px;
  box-shadow: rgb(0, 0, 0) 0px 2px 4px 0px;
  margin-right: -50%;
  transform: translate(-50%, 0px);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.header {
  color: black;
}

.content {
  box-shadow: rgb(0, 0, 0) 0px 2px 4px 0px;
  position: absolute;
  top: 80px;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, 0);
  border: 0px none;
  background: rgb(48, 51, 56);
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 10px;
  min-width: 600px;
}

.container {
  font-size: 16px;
}

.containerOpen {
}

.input {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.inputOpen {
}

.inputFocused {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.suggestionsContainer {
}

.suggestionsContainerOpen {
  overflow: hidden;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  max-height: calc(53.5px * 8);
  margin-top: 10px;
}

.suggestionsList {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
}

.suggestion {
  color: rgb(33, 37, 41);
  border: 1px solid #ced4da;
  border-top: 0px none;
  background-color: #fff;
  padding: 12px 12px;
  cursor: pointer;
}

.suggestion b {
  color: #2f82db;
  font-weight: bold;
}

.suggestionFirst {
}

.suggestionHighlighted {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.spinner {
  border-top: 0.4em solid rgba(255, 255, 255, 0.2);
  border-right: 0.4em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.4em solid rgba(255, 255, 255, 0.2);
  border-left: 0.4em solid rgb(255, 255, 255);
}
