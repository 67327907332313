.accordion {
  width: 100%;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.32s ease-in;
}

.open {
  height: calc(100% - 56px);
  grid-template-rows: 1fr;
}

.inner {
  width: 100%;
  overflow: hidden;
}
